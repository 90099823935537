<template>
  <div class="paypalPaymentError">
    <HomePageTop />
    <div class="content">
      <div class="header">

        {{ $fanyi('支付失败') }}
      </div>

      <div class="main">
        <div class="icon"> <span class="el-icon-close"></span></div>


        <h2>{{ $fanyi('对于给您带来的不便，我们深表歉意，但请重新操作。') }}</h2>
        <div class="buttonbox">
          <div @click="$router.push('/')">{{ $fanyi('返回首页') }}</div>
          <div @click="$router.push('/user/index')">{{ $fanyi('个人中心') }}</div>
          <div @click="$router.push('/user/paymentsummary')">{{ $fanyi('出入金记录') }}</div>

        </div>
      </div>

    </div>
  </div>
</template>

<script>
import HomePageTop from "../../components/head/HomePageTop.vue"; //头部
export default {

  data() {
    return {
    }
  },
  components: {
    HomePageTop,
  },

}
</script>

<style lang="scss" scoped>
.paypalPaymentError {
  background: #f6f6f6;
  height: 100vh;

  .content {
    width: 1400px;
    height: 500px;
    background-color: #fff;
    margin: auto;
    border-radius: 6px;

    .header {
      height: 68px;
      padding: 0 20px;
      display: flex;
      align-items: center;
      font-size: 20px;
      color: black;
      font-weight: 600;

      border-bottom: 1px solid #f6f6f6;

    }

    .main {
      width: 100%;
      height: 100%;
      padding-top: 65px;

      .icon {
        width: 80px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ff730b;
        border-radius: 50%;
        margin: auto;
        margin-bottom: 30px;

        /deep/.el-icon-close {
          font-size: 40px;
          color: #fff;
          font-weight: 700;
        }
      }

      h2 {
        width: 100%;
        text-align: center;
        font-size: 18px;
        margin-bottom: 38px;
      }

      .buttonbox {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        div {
          min-width: 150px;
          height: 48px;
          background: #fff;
          border: 1px solid #ff730b;
          border-radius: 3px;

          border-radius: 6px;


          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          cursor: pointer;
          color: #ff730b;
          font-size: 14px;
          padding: 0 15px;
          margin-left: 25px;

          &:last-child {
            background: #ff730b;
            color: #fff;
          }
        }
      }

    }
  }
}
</style>